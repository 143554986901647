<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-04-07 18:28:07
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2022-05-10 13:51:17
-->
<template>
    <div class="blogs">
      <div class="my-carousel" v-if="bannerList.length">
        <el-carousel
          trigger="click"
          height="214px"
          :interval="4000"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <a
              v-if="item.data.link"
              class="banner-link"
              :href="item.data.link"
              target="blank"
              ><img :src="item.data.image"
            /></a>
            <img v-else :src="item.data.image"  />
          </el-carousel-item>
        </el-carousel>
    </div>
    <AllDynamics></AllDynamics>
  </div>
</template>

<script>
import AllDynamics from './component/AllDynamics.vue'
import { ref } from 'vue'
import { getBanner } from '@/apis/blogs.js'
export default {
  components: {
    AllDynamics
  },
  setup () {
    const changeTime = ref(4000)
    const bannerList = ref([])
    getBanner({ space_id: 1 }).then((res) => {
      if (res.code === 0) {
        bannerList.value = res.data
        if (res.data.length === 2) {
          bannerList.value.push(res.data[0])
          bannerList.value.push(res.data[1])
        }
      }
    })
    return { changeTime, bannerList }
  }
}
</script>

<style lang="less">
.my-carousel {
  width: 100%;
  height: 200px;
  margin-bottom: 8px;
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 6px;
  }
  .banner-link {
    display: block;
  }
  a {
    display: block;
  }
}
</style>
