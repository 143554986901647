<template>
  <div class="blog-cont">
    <div class="all-dynamics">
      <div :class="['nav-logs', isEditorRefVisible ? '' : 'all-border-radius']">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
        >
          <li
            class="tab-title"
            v-for="item in routerData"
            :index="item.index"
            :key="item.index"
          >
            <router-link
              :to="item.routerPath"
              :class="{ 'active': route.path === item.classPass }"
            >
              <template v-if="item.index === 1">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.206 6.077L10 2.155l6.794 3.922v7.846L10 17.845l-6.794-3.922V6.077zM6 7l4 3m0 0v5m0-5l4-3" stroke="#999" stroke-width="2"/></svg>
              </template>
              <template v-else-if="item.index === 2">
                <svg class="secend-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.651 2.49l-1.44 1.44A5.996 5.996 0 0 1 16 9c0 2.17-1.151 4.07-2.876 5.124l1.444 1.444A7.991 7.991 0 0 0 18 9a7.99 7.99 0 0 0-3.349-6.51zM2 9a7.99 7.99 0 0 1 3.348-6.51l1.44 1.44A5.996 5.996 0 0 0 4 9c0 2.17 1.151 4.07 2.876 5.124l-1.444 1.444A7.99 7.99 0 0 1 2 9z" fill="#999"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14 9a4.002 4.002 0 0 1-3.008 3.876c.005.04.008.082.008.124v4a1 1 0 1 1-2 0v-4c0-.042.003-.083.008-.124A4.002 4.002 0 0 1 10 5a4 4 0 0 1 4 4zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#999"/></svg>
              </template>
              <template v-else-if="item.index === 3">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3" width="14" height="14" rx="1" stroke="#999" stroke-width="2"/><rect x="5.5" y="6.5" width="5" height="1" rx=".5" stroke="#999"/><rect x="5.5" y="10.5" width="7" height="1" rx=".5" stroke="#999"/></svg>
              </template>
              {{ item.name }}
            </router-link>
          </li>
        </el-menu>
        <el-button
          type="primary"
          size="mini"
          :class="{ 'disable-btn': isEditorRefVisible }"
          @click="isEditorRefVisible = !isEditorRefVisible"
          >{{ !isEditorRefVisible ? '发个新鲜事' : '等等再写吧' }}</el-button
        >
      </div>
      <div class="edit-wraper" v-if="isEditorRefVisible">
        <CommonEditor
          ref="editorRef"
          editorType="blog"
          placeholder="发点新鲜事吧～"
          limit="1200"
          @submit="publishFeed"
        ></CommonEditor>
      </div>
      <div class="blogs-content">
        <router-view v-if="!isNetWorkErr" :key="route.fullPath"></router-view>
        <template v-else>
          <empty-data
            image="network-error"
            description="网络超时，请重试"
            size="170"
          ></empty-data>
        </template>
      </div>
    </div>
    <HotTopic top="70"></HotTopic>
  </div>
</template>
<script setup>
import { computed, reactive, watch, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { feedsPublishfeed } from '@/apis/blogs.js'
import { addVote } from '@/apis/vote.js'
import { myToast } from '@/utils/dialogMsg.js'
import mySocket from '@/mixin/socket.js'
import eventBus from '@/utils/eventBus.js'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import HotTopic from '@/components/HotTopic.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const isNetWorkErr = computed(() => store.state.isNetWorkErr)

const state = reactive({
  activeIndex: '2',
  isEditorRefVisible: false,
  editorRef: null
})

const { activeIndex, isEditorRefVisible } = toRefs(state)

watch(() => state.isEditorRefVisible, (newValue) => {
  if (!newValue && Object.keys(store.state.vote.voteInfos).length) {
    store.commit('vote/setVoteInfo', {})
  }
})
// 获得分区
store.dispatch('forum/getCatalists')
// tab信息
const routerData = [
  {
    classPass: '/blogs/dynamics',
    routerPath: '/blogs/dynamics?topicId=0',
    index: 2,
    name: '动态'
  },
  {
    classPass: '/blogs/news',
    routerPath: '/blogs/news',
    index: 3,
    name: '资讯'
  },
  {
    classPass: '/blogs/allBlogs',
    routerPath: '/blogs/allBlogs',
    index: 1,
    name: '全部'
  }
]

// 发布动态
const publishFeed = (datas, callback) => {
  feedAndVote(datas)
    .then((res) => {
      if (res.code === 0) {
        const type = Number(datas.publish_status)
        const message = type === 0 ? '保存草稿' : type === 1 ? '动态发布' : '定时动态设置'
        myToast({ type: 'success', message: `${message}成功` })
        state.isEditorRefVisible = false
        eventBus.$emit('uploadBlogsData')
        // 微博小红点清除
        store.commit('editwebSocketType', 0)
        // 返回上一页
        router.push({ path: routerData[0].routerPath })
        // 正常发布，发送webSocket
        if (Number(datas.publish_status) === 1) {
          const { sendToSocket } = mySocket()
          sendToSocket(undefined, { type: 1, feedID: res.data.feed_id })
          if (datas.max_vote_nums) { // 发布了投票，刷新投票列表
            store.dispatch('vote/getProgressVote')
          } else { // 非投票，@了其他人
            sendToSocket(datas.at_user, { type: 4 })
          }
        }
      } else {
        myToast({ type: 'error', message: res.code === 18 ? res.msg : '动态发布失败' })
      }
      // 接口返回调用
      callback(res.code)
    }).catch((err) => {
      console.log(err)
      callback()
    })
}
// 调用接口判断
const feedAndVote = (datas) => {
  if (datas.max_vote_nums) {
    return addVote(datas)
  } else {
    return feedsPublishfeed(datas)
  }
}

</script>

<style lang="less" scoped>
.all-dynamics {
  width: 790px;
  margin-right: 8px;
  border-radius: 6px;
  .nav-logs {
    display: flex;
    justify-content: space-between;
    background-color: @colorWhite;
    padding: 8px 15px;
    border: 1px solid #f1f1f1;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    &.all-border-radius {
      border-radius: 6px;
    }
    .el-button {
      font-size: 14px;
      &.disable-btn {
        background: @other-text-color;
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border: none;
  }
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
  }
  .el-menu-item {
    padding: 0;
  }
  .el-menu-item a {
    padding: 0 20px;
    display: inline-block;
    font-size: 18px;
    width: 100%;
    height: 100%;
  }
}
.blog-cont {
  display: flex;
  justify-content: space-between;
}
.tab-title {
  display: inline-block;
  width: 80px;
  text-align: center;
  &:focus {
    outline-style: none;
  }
  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: @other-text-color;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    border-radius: 15px;
    svg {
      margin-right: 6px;
    }
    &:focus {
      outline-style: none;
    }
    &.active {
      color: @active-text-color;
      font-weight: bold;
      svg path,
      svg rect,
      svg circle {
        stroke: @active-text-color;
      }
      .secend-icon {
        path {
          fill: @active-text-color;
          stroke: transparent;
        }
      }
    }
    &:hover {
      color: @active-text-color;
      font-weight: bold;
      background: #f2f1fe;
      svg path,
      svg rect,
      svg circle {
        stroke: @active-text-color;
      }
      .secend-icon {
        path {
          fill: @active-text-color;
          stroke: transparent;
        }
      }
    }
  }
}
.edit-wraper {
  padding: 8px 24px;
  background: @colorWhite;
  border: 1px solid #f1f1f1;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.blogs-content {
  .el-empty {
    background: @colorWhite;
    padding: 140px 0;
  }
}

@media (max-width: 1023px) {
  .all-dynamics {
    width: 100%;
  }
}
</style>
